import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import classNames from 'classnames/bind';
import { format as fnsFormat, parse } from 'date-fns';

import { YEAR_FULL_FORMAT } from '@common/constants/date';
import { INVALID_DATE_MESSAGE, formatDate, isSameDay } from '@common/utils/dateTimeUtil';
import { dateFormatter } from '@common/utils/formatters/dateFormatter';
import { FaIcon, IconColors } from '@components/FaIcon';
import { TextField } from '@components/TextField';

import styles from './DatePicker.module.css';

const cn = classNames.bind(styles);

export type DatePickerProps = {
  variant?: 'outlined' | 'standard';
  name?: string;
  helperText?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  value: Nullable<string | number | Date>;
  formatter?: (value?: string) => string;
  autoComplete?: string;
  id?: string;
  onChange?: (value: Nullable<Date>) => void;
} & Pick<
  DesktopDatePickerProps<Date, boolean>,
  'className' | 'disabled' | 'format' | 'inputRef' | 'label' | 'maxDate' | 'minDate'
>;

export const DatePicker: FC<DatePickerProps> = ({
  className,
  disabled = false,
  error = false,
  helperText = '',
  label,
  name = '',
  onChange,
  required = false,
  fullWidth = false,
  value = '',
  minDate,
  maxDate,
  format = 'MM/dd/yyyy',
  formatter,
  id,
  inputRef,
  variant,
  autoComplete,
}) => {
  const initialViewValue = useMemo(() => {
    if (!value) {
      return '';
    }
    const formatted = formatDate(value, format);

    return formatted !== INVALID_DATE_MESSAGE ? formatted : '';
  }, [value, format]);

  const initialCalendarValue = useMemo(
    () => (initialViewValue ? parse(initialViewValue, format, new Date()) : null),
    [initialViewValue, format],
  );

  const [viewValue, setViewValue] = useState(initialViewValue);

  const handleChange = useCallback(
    (formattedValue: Nullable<Date>) => {
      const formatted = formattedValue ? fnsFormat(formattedValue, format) : '';
      setViewValue(formatted ?? formattedValue?.toLocaleString() ?? '');
      onChange?.(formattedValue);
    },
    [onChange],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const formatFn = formatter ?? dateFormatter({ inputFormat: format });
      const rawValue = event.target.value;
      setViewValue(formatFn(rawValue));
      const parsedValue = parse(rawValue, format, new Date());
      onChange?.(parsedValue.toString() === INVALID_DATE_MESSAGE ? null : parsedValue);
    },
    [format, formatter, onChange],
  );

  const activeDate = new Date();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        className={cn('root', className)}
        format={format}
        value={initialCalendarValue}
        onChange={handleChange}
        disabled={disabled}
        label={label}
        minDate={minDate}
        maxDate={maxDate}
        inputRef={inputRef}
        slotProps={{
          openPickerButton: {
            'aria-haspopup': 'dialog',
            className: cn('picker-button'),
          },
          openPickerIcon: {
            iconName: 'calendar',
            iconPrefix: 'far',
            color: IconColors.Gray,
            className: cn('picker-icon'),
          },
          day: ({ day, selected }) => ({
            className: cn('calendar-button', {
              active: isSameDay(day, activeDate),
              selected,
            }),
          }),
          yearButton: ({ value, selected }) => ({
            className: cn('calendar-button', {
              active: value === Number(fnsFormat(activeDate, YEAR_FULL_FORMAT)),
              selected,
            }),
          }),
          /* eslint-disable @typescript-eslint/no-explicit-any */
          field: {
            id,
            value: viewValue || initialViewValue,
            name,
            inputProps: {
              value: viewValue || initialViewValue,
            },
            placeholder: format.toLowerCase(),
            required,
            helperText,
            error,
            fullWidth,
            autoComplete,
            variant,
            onChange: handleInputChange,
          } as any,
        }}
        slots={{
          openPickerIcon: FaIcon,
          field: TextField,
        }}
      />
    </LocalizationProvider>
  );
};
